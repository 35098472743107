import slidesLiveDomain from 'plugins/utilities/slideslive_domain';

import now from './now';

class DelayedResultCheck {
  constructor(urlOrDelayedResultId, checkInterval, callback, errorsCallback) {
    this.url = this.urlOrDelayedResultIdToUrl(urlOrDelayedResultId);
    this.checkInterval = checkInterval || 2000;
    this.callback = callback;
    this.errorsCallback = errorsCallback;

    this.checkTimeoutId = null;
    this.request = null;
    this.abortController = null;
    this.aborted = false;

    this.startedAt = now();
    this.maxDuration = 30 * 60 * 1000;
  }

  abort() {
    this.aborted = true;

    if (this.request) {
      this.abortController.abort();
      this.abortController = null;
      this.request = null;
    }

    if (this.checkTimeoutId) {
      clearTimeout(this.checkTimeoutId);
      this.checkTimeoutId = null;
    }
  }

  scheduleCheck(checkInterval = null) {
    if (this.aborted) {
      return;
    }

    this.checkTimeoutId = setTimeout(this.check.bind(this), checkInterval || this.checkInterval);
  }

  check() {
    if (this.checkTimeoutId) {
      clearTimeout(this.checkTimeoutId);
      this.checkTimeoutId = null;
    }

    if (this.elapsedTime > this.maxDuration) {
      this.abort();

      if (this.errorsCallback) {
        this.errorsCallback([`Timed out after ${this.maxDuration / 1000.0} seconds.`]);
      }

      return;
    }

    if (this.aborted) {
      return;
    }

    this.abortController = new AbortController();
    this.request = fetch(this.url, {
      signal: this.abortController.signal,
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'omit',
      headers: { Accept: 'text/plain' },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
    })
      .catch(() => this.scheduleCheck())
      .then((response) => response.json())
      .then((response) => {
        if (!response.success) {
          console.warn('DelayedResult errors:', response.errors);

          if (this.errorsCallback) {
            this.errorsCallback(response.errors || ['Server error.']);
          }

          return;
        }

        if (response.data.status === 'success' || response.data.status === 'fail') {
          this.callback(response.data.result);
        } else {
          this.scheduleCheck();
        }
      })
      .catch((error) => {
        console.warn(error);

        this.scheduleCheck();
      });
  }

  urlOrDelayedResultIdToUrl(urlOrDelayedResultId) {
    let url;
    if (urlOrDelayedResultId.indexOf('/') < 0) {
      url = `${this.delayedResultUrl()}?id=${urlOrDelayedResultId}`;
    } else {
      url = urlOrDelayedResultId;
    }

    return url;
  }

  delayedResultUrl() {
    if (gon && gon.delayed_result_url) {
      return gon && gon.delayed_result_url;
    }

    const domain = slidesLiveDomain();
    return `https://${domain}/-/delayed_result`;
  }

  get elapsedTime() {
    return now() - this.startedAt;
  }
}

function checkDelayedResult(urlOrDelayedResultId, callback, checkInterval, errorsCallback) {
  const check = new DelayedResultCheck(urlOrDelayedResultId, checkInterval, callback, errorsCallback);
  check.scheduleCheck(750);
  return check;
}

export default checkDelayedResult;
